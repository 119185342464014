html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body, html {
    margin: 0;
    overflow: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Neue Helvetica';
}

*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
body {
    background-color: white;
}
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

textarea {
    resize: none;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none;
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

p {
    margin-bottom: initial;
}