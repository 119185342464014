@font-face {
    font-family: 'Neue Helvetica';
    src: url('./fonts/Helvetica-Neue-LT-65-Medium.ttf'); /* IE9 Compat Modes */
    font-weight: 500;
}

@font-face {
    font-family: 'Neue Helvetica';
    src: url('./fonts/Helvetica-Neue-LT-75-Bold.ttf'); /* IE9 Compat Modes */
    font-weight: 700;
}